<template>
  <div
    class="min-h-screen flex items-center lg:items-start justify-center p-4 sm:p-10 lg:pt-[120px] lg:pr-[148px] lg:pl-[80px] bg-cover bg-[url('/images/login-bg.svg')]"
  >
    <div class="container">
      <div class="flex lg:flex-row flex-col gap-6 sm:justify-between">
        <div class="flex justify-center lg:justify-start items-start">
          <img src="/images/kp-logo.svg" alt="logo-kp" class="w-[204px]">
        </div>
        <div class="flex justify-center lg:justify-end w-full">
          <div class="bg-white rounded-[20px] py-[50px] lg:py-[80px] flex flex-col gap-10 lg:w-[564px] sm:w-9/12 w-full">
            <div class="flex flex-col gap-5">
              <div class="flex justify-center">
                <img src="/images/sally-logo-colored.svg" alt="logo-sally" class="w-[150px]">
              </div>
              <h1 class="text-2xl text-center font-semibold leading-8">
                <slot name="title" />
              </h1>
            </div>
            <div class="lg:px-8 px-6">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
